import React from 'react';
import { Flex, Box, Label } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import useTranslation from '@hooks/useTranslation';
import gameConfig from '@content/gameconfig';
import LanguageModalButton from './LanguageModalButton';
import SoundModalButton from './SoundModalButton';

const Wrapper = styled(Box).attrs({ as: 'footer' })(p =>
  css({
    isolation: 'isolate',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    pl: 4,
    pb: p.showLanguageToggle ? 0 : 3,
  }),
);

const Footer = () => {
  const { t } = useTranslation();
  const { Show_Made_By_Footer = true, Show_Language_Toggle = true } =
    gameConfig;
  return (
    <Wrapper showLanguageToggle={Show_Language_Toggle}>
      <Flex justifyContent="space-between">
        <Label flexDirection="row">
          <SoundModalButton
            modalTitle={t('Sound Modal Header')}
            buttonProps={{ mr: 1, id: 'sound-btn' }}
          />
          <Label as="span" variant="l3">
            {t('Sound Button')}
          </Label>
        </Label>
        {Show_Language_Toggle && (
          <Flex flex={1} justifyContent="flex-end">
            <LanguageModalButton />
          </Flex>
        )}
      </Flex>
    </Wrapper>
  );
};

export default Footer;
