import { Middleware } from 'redux';
import { routeSlice } from '@slices/routeSlice';
import { gameStateSlice } from '@slices/gameStateSlice';
import { logItem } from '@slices/loggingSlice';
import { levellingSlice } from '@slices/levellingSlice';
import * as loggingClient from '@utils/loggingClient';
import { RootState } from '../store';
import getQuestionNameFromCurrentRoute from '@utils/getQuestionNameFromCurrentRoute';

const createInterceptorMiddleware = (interceptors: Intercept[]) => {
  const middleware: Middleware<
    {}, // Most middleware do not modify the dispatch return value
    RootState
  > = store => next => action => {
    Promise.all(
      interceptors
        .filter(interceptor => interceptor.type === action.type)
        .map(interceptor => {
          const result = interceptor.handler(
            action,
            store.dispatch,
            store.getState,
          );
          return result instanceof Promise ? result : Promise.resolve(result);
        }),
    )
      .then(afterDispatchHandlers => {
        next(action);
        afterDispatchHandlers.forEach(
          handler =>
            typeof handler === 'function' &&
            handler(action, store.dispatch, store.getState),
        );
      })
      .catch(e => console.error(e));
  };
  return middleware;
};

interface Intercept {
  type: string;
  // TODO: fix the types
  handler: (e: any, d: (e: any) => void, s: () => RootState) => void;
}

const interceptors = [
  {
    type: levellingSlice.actions.questionHasBeenAnswered.type,
    handler: () => (e, dispatch, getState) => {
      const state = getState();
      const { pointsForLevel, pointsThreshold } = state.levelling;
      /** if we have levelled up, we need to add 1 to our index
       *  this is because the points are given BEFORE we reset our levelling brackets
       *  however we don't care about that logic, we just need to know that you hit a threshold
       */
      if (pointsForLevel === pointsThreshold) {
        // we need to know the state AFTER the action
        dispatch(
          logItem({
            collection_name: 'events',
            event_type: 'rank_up',
            target: getState().levelling.currentLevel + 1,
          }),
        );
      }
    },
  },
  {
    type: routeSlice.actions.setCurrentStream.type,
    handler: ({ payload }, dispatch, getState) => {
      const stream = payload.streamName;
      // recording that the stream changed
      dispatch(
        logItem({
          event_type: 'set_stream',
          target: stream,
          collection_name: 'events',
        }),
      );
      // updating the logger record with the new language
      loggingClient.setStreamName(stream, getState().logging.sessionId);
    },
  },
  {
    type: gameStateSlice.actions.setCurrentLanguage.type,
    handler: ({ payload }, dispatch, getState) => {
      const { logging, route } = getState();
      // a set language would occur before the session is initialized, so this is a safeguard to prevent fb err
      if (logging.sessionId) {
        const customEventType = payload.eventType;
        const lang = payload.setTo;
        // recording that the language changed
        dispatch(
          logItem({
            event_type: customEventType || 'toggle_lang',
            target: lang,
            question_name: getQuestionNameFromCurrentRoute(route.currentRoute),
            // event_description: `user changed language to ${lang}`,
            collection_name: 'events',
          }),
        );
        // updating the logger record with the new language
        loggingClient.setLanguage(lang, getState().logging.sessionId);
      }
    },
  },
  {
    type: 'logging/startNewSession/fulfilled',
    handler: ({ payload }, dispatch, getState) => {
      if (typeof window !== 'undefined' && window?.entryTime && payload) {
        // we got a session, and therefore we log the delta (time it took to get it)
        const { entryTime } = window;
        const currTime = Date.now();
        const { logging } = getState();
        const timeDelta = currTime - entryTime;
        loggingClient.log(
          {
            event_type: 'session_init_in_ms',
            target: timeDelta,
            collection_name: 'events',
            step_counter: logging?.logStepCounter || 0,
          },
          payload,
        );
      }
    },
  },
  {
    type: gameStateSlice.actions.gameHasFinished.type,
    handler: ({ payload }, dispatch, getState) => {
      // updating the logger record with game end flag
      dispatch(
        logItem({
          event_type: 'finished_game',
          target: 'user_finished_game',
          // event_description: `user changed language to ${lang}`,
          collection_name: 'events',
        }),
      );
      // TODO: do this later
      // loggingClient.setGameComplete(getState()?.logging?.sessionId);
    },
  },
] as Intercept[];

const middleware = (): Middleware => createInterceptorMiddleware(interceptors);

export default middleware;
