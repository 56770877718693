import React, { ComponentType, ReactNode, useRef } from 'react';
import {
  useOverlay,
  usePreventScroll,
  OverlayContainer,
  useModal,
} from '@react-aria/overlays';
import { ModalProps } from '@react-types/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import styled from 'styled-components';
import css from '@styled-system/css';
import { motion, AnimatePresence } from 'framer-motion';
import { useVisuallyHidden } from '@react-aria/visually-hidden';
import { Box, Flex } from '../Primitives';

interface LocalModalProps extends ModalProps {
  title: string;
  AsideImage?: ComponentType;
  showBgImage: boolean;
  HeaderComponent: ComponentType;
  sx: Record<string, unknown>;
  children: ReactNode;
}

const Wrapper = styled(Box)(
  css({
    position: 'fixed',
    zIndex: 'skyscraper',
    maxHeight: '100vh',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    backgroundSize: ['0%', '75% 100%', '50% 100%'],
    backgroundPositionX: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

const ImageWrapper = styled(Flex)(
  css({
    width: '100%',
    position: 'absolute',
    top: 0,
    minWidth: '100%',
    minHeight: '100%',
    display: ['none', 'inherit', 'inherit'],
    '.gatsby-image-wrapper': {
      width: '50vw',
      height: '100vh',
    },
  }),
);

const Body = styled(Box)(
  css({
    backgroundColor: 'surface',
    color: 'black',
    top: 0,
    height: '100%',
    position: 'absolute',
    left: 0,
    width: ['100%', '50%', '50%'],
    overflowY: 'auto',
  }),
);

const Tray = (props: LocalModalProps) => {
  const { visuallyHiddenProps } = useVisuallyHidden();
  const {
    title = 'dummy',
    HeaderComponent,
    AsideImage,
    showBgImage,
    children,
    sx,
  } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = useRef();
  const { overlayProps, underlayProps } = useOverlay(props, ref);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref);
  return (
    <AnimatePresence>
      {props.isOpen && (
        <OverlayContainer>
          <Wrapper
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            {...underlayProps}
          >
            <FocusScope key="focus-modal" contain restoreFocus autoFocus>
              <Flex>
                <Body
                  sx={sx}
                  {...overlayProps}
                  {...dialogProps}
                  {...modalProps}
                  ref={ref}
                >
                  {HeaderComponent && <HeaderComponent />}
                  <Flex
                    flexDirection="column"
                    mb={6}
                    px={[6, 11, 11]}
                    pb={[6, 11, 11]}
                  >
                    <Flex>
                      <p {...titleProps} {...visuallyHiddenProps}>
                        {title}
                      </p>
                    </Flex>
                    {children}
                  </Flex>
                </Body>
                {AsideImage && showBgImage && (
                  <ImageWrapper>
                    <AsideImage />
                  </ImageWrapper>
                )}
              </Flex>
            </FocusScope>
          </Wrapper>
        </OverlayContainer>
      )}
    </AnimatePresence>
  );
};

export default Tray;
