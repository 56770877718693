import React, { ReactChildren } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import css from '@styled-system/css';
import { Flex } from '../Primitives';

const Wrapper = styled(Flex)(
  css({
    backgroundColor: 'hsla(0, 0%, 0%, 0.9)',
    color: 'white',
    borderRadius: '8px',
    listStyle: 'none',
    px: [5, 6, 6],
    py: [3, 5, 5],
    width: 'fit-content',
  }),
);

interface Props {
  /** provide a key if you are rendering this in a map */
  key?: string | number;
  /** defaults to a flex dev */
  as?: string;
  children: ReactChildren;
}

/** This is a single notification, this is often used within a notification stacking system */
const Notification = (props: Props) => {
  const { as, ...delegated } = props;
  return (
    <Wrapper
      as={props?.as ? motion[props.as] : motion.li}
      layout
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      {...delegated}
    >
      {props.children}
    </Wrapper>
  );
};

export default Notification;
