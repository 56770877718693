import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useVisuallyHidden } from '@react-aria/visually-hidden';
import { useAppSelector } from '@hooks/redux-hooks';
import css from '@styled-system/css';
import { Flex, Label, ProgressBar, Box } from 'workspace-core-ui';
import LevelStar from '@components/LevelStar';
import useTranslation from '@hooks/useTranslation';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import AboutTrayButton from './AboutTrayButton';
import ShareModalButton from './ShareModalButton';

const QuestionCounter = () => {
  const { claimIndex, totalClaims } = useAppSelector(s => s.levelling);
  const controls = useAnimation();
  const { t } = useTranslation();

  useEffect(() => {
    controls.start({
      scale: 1,
      transition: {
        type: 'spring',
        velocity: 50,
        stiffness: 700,
        damping: 80,
      },
    });
  }, [claimIndex, controls]);
  return (
    <Flex>
      <Label mr="3px" as={motion.div} animate={controls}>
        {claimIndex}
      </Label>
      <Label variant="l2">
        {t('Claim Score Interstice')} {totalClaims}
      </Label>
    </Flex>
  );
};

const HeaderWrapper = styled(Flex).attrs({
  as: 'header',
})(
  css({
    height: '70px',
    width: '100%',
    p: 4,
    pt: 0,
    position: 'fixed',
    zIndex: 'towerBlock',
  }),
);

// a label with text
const Multilabel = styled(Label).attrs({ variant: 'l3' })({
  flexDirection: 'row',
});

const Header = () => {
  const [isLevellingUp, setIsLevellingUp] = useState(false);
  const { pointsForLevel, currentLevel, pointsThreshold } = useAppSelector(
    s => s.levelling,
  );
  const { headerType } = useAppSelector(s => s.gameState);
  const { visuallyHiddenProps } = useVisuallyHidden();
  const { t } = useTranslation();
  const shareModalTitle = t('Share Modal Header');
  const progressBarValue = (pointsForLevel / pointsThreshold) * 100;
  const shouldReduceMotion = useCustomReducedMotion();

  const headerElems = (() => {
    const iden = {
      // the about and sound are always shown
      showLabels: false,
      showQuestionCount: false,
      showLevelStar: false,
      showShare: false,
    };
    switch (headerType) {
      case 'intro':
        iden.showLabels = true;
        break;
      case 'full':
        iden.showQuestionCount = true;
        iden.showLevelStar = true;
        break;
      case 'recall':
        iden.showLevelStar = true;
        break;
      case 'shareable':
        iden.showLevelStar = false;
        iden.showQuestionCount = false;
        break;
      case 'minimal':
      default:
        break;
    }

    return iden;
  })();

  return (
    <HeaderWrapper>
      <AnimatePresence initial={false} exitBeforeEnter>
        {headerElems.showLabels ? (
          <Flex
            key="label-icons"
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Multilabel mr={[2, 4, 4]}>
              <AboutTrayButton buttonProps={{ mr: 1 }} />
              <Label as="span" variant="l3">
                {t('About Button')}
              </Label>
            </Multilabel>
            <Multilabel>
              <ShareModalButton
                loggingTarget="share_modal_button_header"
                modalTitle={shareModalTitle}
                buttonProps={{ mr: 1, id: 'share-btn' }}
              />
              <Label as="span" variant="l3">
                {t('Share Modal Header')}
              </Label>
            </Multilabel>
          </Flex>
        ) : (
          <Flex
            key="icons"
            flex={1}
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Label variant="l3" mr={2}>
              <AboutTrayButton />
              <span {...visuallyHiddenProps}>{t('About Button')}</span>
            </Label>
            <Label variant="l3">
              <ShareModalButton
                loggingTarget="share_modal_button_header"
                modalTitle={shareModalTitle}
                buttonProps={{ mr: 1, id: 'share-btn' }}
              />
              <span {...visuallyHiddenProps}>{t('Share Modal Header')}</span>
            </Label>
          </Flex>
        )}
      </AnimatePresence>
      <Flex justifyContent="center" flex={1}>
        {headerElems.showQuestionCount && <QuestionCounter />}
      </Flex>
      <Flex flex={1} alignItems="center" justifyContent="flex-end">
        {headerElems.showLevelStar && (
          <>
            <Box width="60px" mr="-12px">
              <ProgressBar
                hasFlatRightEar={false}
                value={progressBarValue}
                onAnimationStart={() => {
                  setIsLevellingUp(false);
                }}
                onAnimationComplete={() => {
                  setIsLevellingUp(progressBarValue === 100);
                }}
              />
            </Box>
            <LevelStar
              count={currentLevel}
              isLevellingUp={isLevellingUp}
              reducedMotion={shouldReduceMotion}
            />
          </>
        )}
        {headerElems.showShare && (
          <ShareModalButton
            loggingTarget="share_modal_button_header"
            modalTitle={t('Share Modal Header')}
          />
        )}
      </Flex>
    </HeaderWrapper>
  );
};

export default Header;
