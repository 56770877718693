import React from 'react';
import { Modal, Flex, IconButton, Label, Divider } from 'workspace-core-ui';
import { useOverlayTriggerState } from '@react-stately/overlays';
import Toggle from 'workspace-core-ui/Toggle';
import {
  setIsBackgroundMusicOn,
  setAreSoundEffectsOn,
} from '@slices/gameStateSlice';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import useTranslation from '@hooks/useTranslation';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import { logItem } from '@slices/loggingSlice';
import { addBackgroundMusic, addGameSounds } from '@slices/audioSlice';

interface SoundModalButtonProps {
  modalTitle: string;
  buttonProps: any;
}

const SoundModalButton = (props: SoundModalButtonProps) => {
  const { t } = useTranslation();
  const { buttonProps, modalTitle } = props;
  const overlayState = useOverlayTriggerState({});
  const dispatch = useAppDispatch();
  // control states
  const { isBackgroundMusicOn, areSoundEffectsOn } = useAppSelector(
    s => s.gameState,
  );
  const shouldReduceMotion = useCustomReducedMotion();

  return (
    <>
      <IconButton
        sx={{
          mt: ['-2px', '-4px'],
        }}
        showOutline={false}
        onPress={() => overlayState.open()}
        iconName="music"
        // TODO: localize ARIA
        aria-label="sound"
        data-cy="soundButton"
        {...buttonProps}
      />
      <Modal
        title={modalTitle}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        isDismissable
        showClose
        closeLabel={t('Close')}
        modalBodyStyles={{
          bottom: 4,
          left: ['unset', '16px', '16px'],
        }}
      >
        <Flex mt={5} as="form" flexDirection="column">
          <Label variant="l3">
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              {t('Sound Modal Music Toggle')}
              <Toggle
                aria-label={t('Sound Modal Music Toggle')}
                onLabel={t('On Label')}
                offLabel={t('Off Label')}
                reduceMotion={shouldReduceMotion}
                data-cy="musicToggle"
                isSelected={isBackgroundMusicOn}
                onChange={isSelected => {
                  if (isSelected) {
                    dispatch(addBackgroundMusic());
                  }
                  dispatch(
                    logItem({
                      collection_name: 'events',
                      event_type: 'toggle_settings',
                      target: `music_${isSelected ? 'on' : 'off'}`,
                    }),
                  );
                  dispatch(
                    setIsBackgroundMusicOn({
                      setTo: isSelected,
                    }),
                  );
                }}
              />
            </Flex>
          </Label>
          <Divider my={1} />
          <Label variant="l3">
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              {t('Sound Modal SFX Toggle')}
              <Toggle
                aria-label={t('Sound Modal SFX Toggle')}
                onLabel={t('On Label')}
                offLabel={t('Off Label')}
                reduceMotion={shouldReduceMotion}
                data-cy="soundToggle"
                isSelected={areSoundEffectsOn}
                onChange={isSelected => {
                  // lazy loading internal sounds here
                  if (isSelected) {
                    dispatch(addGameSounds());
                  }
                  dispatch(
                    logItem({
                      collection_name: 'events',
                      event_type: 'toggle_settings',
                      target: `sound_${isSelected ? 'on' : 'off'}`,
                    }),
                  );
                  dispatch(setAreSoundEffectsOn({ setTo: isSelected }));
                }}
              />
            </Flex>
          </Label>
        </Flex>
      </Modal>
    </>
  );
};

export default SoundModalButton;
