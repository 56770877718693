import styled, { StyledComponent } from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';
import BaseText, { BaseTextProps } from '../Primitives/BaseText';
import { ButtonContentStyled } from '../Buttons';

interface LabelProps {
  variant?: 'l1' | 'l2' | 'l3' | 'l4';
  as?: string;
}

const Label: StyledComponent<'p', any, LabelProps & BaseTextProps, never> =
  styled(BaseText)(
    ({
      color,
      textDecoration,
      flexDirection = 'column',
      alignItems,
      alignSelf,
    }) =>
      css({
        textDecoration: textDecoration || 'inherit',
        flexDirection,
        justifyContent: 'center',
        alignItems: alignItems || 'center',
        alignSelf: alignSelf || 'initial',
        display: 'flex',
        lineHeight: '100%',
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: '500',
        color: color || 'text',
        // if inside a button or anchor, inherit buttons color settings
        [`button,a,${ButtonContentStyled} &`]: {
          color: 'inherit',
        },
      }),
    variant({
      variants: {
        l1: {
          fontSize: [4, 5, 6],
        },
        l2: {
          fontSize: [3, 4, 4],
          fontWeight: '700',
        },
        l3: {
          fontSize: [3, 4, 4],
        },
        l4: {
          fontSize: [2, 3, 3],
        },
      },
    }),
  );

Label.defaultProps = {
  variant: 'l2',
  as: 'label',
};

export default Label;
