import { createSlice } from '@reduxjs/toolkit';
import sounds from '@content/sounds.json';

const backgroundMusicData = {
  // specify the music key to make it loop
  Music: {
    src: [sounds.Music],
    loop: true,
  },
};

// these are loaded lazily once you actually enter the game or toggle sounds
const gameSpecificSounds = {
  Button: sounds.Button,
  Incorrect: sounds.Incorrect,
  Correct: sounds.Correct,
};

export const audioSlice = createSlice({
  name: 'audioState',
  initialState: {
    backgroundMusicLoaded: false,
  },
  reducers: {
    addBackgroundMusic: {
      reducer: state => {
        state.backgroundMusicLoaded = true;
      },
      prepare: (payload = {}) => ({
        payload,
        meta: {
          sound: {
            add: backgroundMusicData,
          },
        },
      }),
    },
    addGameSounds: {
      reducer: state => {},
      prepare: (payload = {}) => ({
        payload,
        meta: {
          sound: {
            add: gameSpecificSounds,
          },
        },
      }),
    },
  },
});

export const { addBackgroundMusic, addGameSounds } = audioSlice.actions;

export default audioSlice.reducer;

// export const fadeEndTurn = createAction('audio/STOP_END_TURN', () => ({
//   meta: {
//     sound: {
//       stop: 'endTurn',
//     },
//   },
// }));

// export const addSound2 = createAction('audio/ADD_SOUND_TWO', () => ({
//   meta: {
//     sound: {
//       add: {
//         heavyCoin: {
//           src: [
//             `${process.env.PUBLIC_URL}/sound2.mp3`,
//             `${process.env.PUBLIC_URL}/sound2.webm`,
//           ],
//           volume: 0.75,
//         },
//       },
//     },
//   },
// }));

// export const playSound2 = createAction('audio/PLAY_SOUND_TWO', () => ({
//   meta: {
//     sound: {
//       play: 'heavyCoin',
//     },
//   },
// }));

// export const stopSound2 = createAction('audio/STOP_SOUND_TWO', () => ({
//   meta: {
//     sound: {
//       stop: 'heavyCoin',
//     },
//   },
// }));
